import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { Flex, Button, Input, Link,Box } from 'theme-ui'

import {
    App,
    Profile,
    ProfileEdit,
    Connect,
    Mint,
    TokenDetail,
    Watermark,
    Artworks,
    Login,
    ArtistGallery,
    Assets,
    Collections,
    CollectionsList
} from './'
import  Homepage from './searchEngine/homepage'

import Header from '../components/Header'
import  { useCookies } from 'react-cookie'
// @ts-ignore
import Script from 'react-load-script'
import {utils} from "../utils";

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

const RootSearch = () => {
    let passwordInput:string = ''
    let passwordLogin:boolean = false
    const passwordRight = process.env.REACT_APP_SCOPEX_PASSWORD
    const [cookies, setCookie] = useCookies(['cookie-name']);
    // @ts-ignore
    if(cookies.scopexLogin === 'true') {
      passwordLogin = true
    }

  return (
      !passwordLogin ? (
          <Router basename={process.env.PUBLIC_URL}>
            <Flex sx={{ justifyContent: 'center',marginTop:'20%' }}>
              <Input
                  sx={{
                    marginTop: '10px',
                    color: 'black',
                    backgroundColor: 'white',
                    width: 150,
                    height: '100%',
                    margin:0
                  }}
                  onChange={event => passwordInput =event.target.value }
                  onKeyPress={(e) =>{
                      if(e.key !== 'Enter') return
                      if(passwordRight === passwordInput){
                          passwordLogin=true
                          // @ts-ignore
                          setCookie('scopexLogin','true')
                          window.location.reload()
                      }else{
                          alert('Sorry, the password you input is not correct.')
                      }
                  }}
              />
            <Button
                mt={2}
                variant="connect"
                sx={{
                  position: 'relative',
                  marginTop: '10px',
                  color: 'white',
                  backgroundColor: '#3333ee',
                  width: 100,
                  height: 30,
                  margin:0,
                  marginLeft:'20px'
                }}
                onClick={() => {
                  if(passwordRight === passwordInput){
                    passwordLogin=true
                    // @ts-ignore
                      setCookie('scopexLogin','true')
                      window.location.reload()
                  }else{
                    alert('Sorry, the password you input is not correct.')
                  }
                }}
            >Login</Button>
            </Flex>
          </Router>
      ):(
          <Router basename={process.env.PUBLIC_URL}>
              <Web3ReactProvider getLibrary={getLibrary}>
                  <Connect>
            <Header/>
              {/*<div style={{marginLeft:'30px',marginRight:'30px'}}>*/}
                <Switch>
                  <Route path="/mint" component={Mint}/>
                  <Route path="/profile" component={Profile}/>
                    <Route path="/editprofile" component={ProfileEdit}/>
                    <Route path="/artistGallery" component={ArtistGallery}/>
                    <Route path="/artworks" component={Artworks}/>
                    <Route path="/assets" component={Assets}/>
                    <Route path="/collections" component={Collections}/>
                    <Route path="/collectionsList" component={CollectionsList}/>
                    <Route exact path="/" component={Homepage}/>
                    <Route path="/tokenDetail" component={TokenDetail}/>
                    <Route path="/login" component={Login}/>
                    <Route path="/verify" component={Watermark}/>
                </Switch>
              {/*</div>*/}
              </Connect>
            </Web3ReactProvider>
          </Router>
      )
  )
}

export default RootSearch
