import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import  Feature from './OfficialSite/feature'
// @ts-ignore
import  Features from './OfficialSite/features'
// @ts-ignore
import HomepageAllInOne from './OfficialSite/homepageAllInOne'
import  About from './OfficialSite/about'
// @ts-ignore
import  Contact from './OfficialSite/contact'
import HeaderOfficial from '../components/HeaderOfficial'
// @ts-ignore
import Script from 'react-load-script'



const RootHomepage = () => {
  return (
          <Router basename={process.env.PUBLIC_URL}>
            <HeaderOfficial/>
                <Switch>
                    <Route exact path="/" component={HomepageAllInOne}/>
                    <Route exact path="/feature" component={Feature}/>
                    <Route exact path="/features" component={Features}/>
                    <Route exact path="/about" component={About}/>
                    <Route exact path="/contact" component={Contact}/>
                </Switch>
          </Router>
      )
}

export default RootHomepage
