const utils = {

    isMobile: () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    },

    getBase64: (imgUrl:string)=> {
    return new Promise(((resolve, reject) => {
        window.URL = window.URL || window.webkitURL;
        const xhr = new XMLHttpRequest();
        xhr.open('get', imgUrl, true);
        xhr.responseType = 'blob';
        xhr.send();
        xhr.onload = function () {
            if (this.status === 200) {
                const blob = this.response;
                const oFileReader = new FileReader();
                oFileReader.onloadend = function (e) {
                    // @ts-ignore
                    const base64 = e.target.result;
                    resolve(base64);
                };
                oFileReader.onerror = function (e) {
                    reject();
                };
                oFileReader.readAsDataURL(blob);
            }
        };
    }));
},
    formatDisplayHashAddress:  (address:string)=>{
        let perfix = address.substring(0,6)
        let suffix = address.slice(-4)
        return perfix+'...'+suffix
    }

}

export default utils
