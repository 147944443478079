import { Container, Flex, Button, Spinner, Image } from 'theme-ui'
import { useWeb3React } from '@web3-react/core'
import { AbstractConnector } from '@web3-react/abstract-connector'

import  OfficialHomepageAllInOne  from '../../components/OfficialHomepage/OfficialHomepageAllInOne'
import {  useStateContext } from '../../state'


const HomepageAllInOne = () => {
  const {
    dispatch,
    state: { user, activatingConnector },
  } = useStateContext()


  return (
    <Container>
      <OfficialHomepageAllInOne />
    </Container>
  )
}

export default HomepageAllInOne
