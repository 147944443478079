// import React, { useState } from "react"
import utils from "../../utils/tools"
// import axios from 'axios';

import './OfficialHomepageAllInOne.css'
// import Footer from './footer'
// import {getIPFSSignature} from "../../actions";

const OfficialHomepageAllInOne = () => {
    // let [emailValue, setEmailValue] = useState('')
    // let [fNameValue, setFNameValue] = useState('')
    // let [messageValue,setMessageValue]= useState('')
    //


    const isMobile:boolean = utils.isMobile()

    const contactForm =  '<div id="mc_embed_signup" style="background:unset;width: auto">\n' +
        '<form action="https://scopex.us5.list-manage.com/subscribe/post?u=2e85f59032bf23a0dfb89d73e&amp;id=f5eda6f1da" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>\n' +
        '    <div id="mc_embed_signup_scroll" style="">\n' +
        '\t\n' +
        '<div class="mc-field-group"><div class="mc-field-group-title">Name</div><div>\n' +
        '<input  class="mc-field-group-input"  type="text" value="" name="FNAME"  id="mce-FNAME"></div>\n' +
        '</div>\n' +
        '<div class="mc-field-group"><div class="mc-field-group-title">Email</div><div>\n' +
        '<input class="mc-field-group-input" type="email" value="" name="EMAIL" placeholder="" id="mce-EMAIL"></div>\n' +
        '</div>\n' +
        '<div class="mc-field-group"><div class="mc-field-group-title">Message</div><div>\n' +
        '<textarea class="mc-field-group-input" style="height: 120px;" type="text"  name="MMERGE6" value=""  id="mce-MMERGE6"></textarea></div>\n' +
        '</div>\n' +
        '\t<div id="mce-responses" class="clear">\n' +
        '\t\t<div class="response" id="mce-error-response" style="display:none"></div>\n' +
        '\t\t<div class="response" id="mce-success-response" style="display:none"></div>\n' +
        '\t</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->\n' +
        '    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_2e85f59032bf23a0dfb89d73e_f5eda6f1da" tabindex="-1" value=""></div>\n' +
        '    <div class="clear" style="    display: flex;\n' +
        '    justify-content: center;"><input type="submit" value="Submit" name="subscribe" id="mc-embedded-subscribe" class="signUpButton"></div>\n' +
        '    </div>\n' +
        '</form>\n' +
        '</div>'

    const mailForm = '<div id="mc_embed_signup" style="background:unset;width: auto">\n' +
        '<form action="https://scopex.us5.list-manage.com/subscribe/post?u=2e85f59032bf23a0dfb89d73e&amp;id=f5eda6f1da" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>\n' +
        '    <div id="mc_embed_signup_scroll" style="display: flex">\n' +
        '\t\n' +
        '<div class="mc-field-group">\n' +
        '<input type="email" value="" name="EMAIL" class="required email" style="opacity: 50%;" placeholder="Your email address" id="mce-EMAIL">\n' +
        '</div>\n' +
        '\t<div id="mce-responses" class="clear">\n' +
        '\t\t<div class="response" id="mce-error-response" style="display:none"></div>\n' +
        '\t\t<div class="response" id="mce-success-response" style="display:none"></div>\n' +
        '\t</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->\n' +
        '    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_2e85f59032bf23a0dfb89d73e_f5eda6f1da" tabindex="-1" value=""></div>\n' +
        '    <div class="clear" style="margin-left:10px"><input type="submit" value="Sign up" name="subscribe" id="mc-embedded-subscribe" class="signUpButton"></div>\n' +
        '    </div>\n' +
        '</form>\n' +
        '</div>'

    const mailFormFooter = '<div id="mc_embed_signup" style="background:unset;width: auto">\n' +
        '<form action="https://scopex.us5.list-manage.com/subscribe/post?u=2e85f59032bf23a0dfb89d73e&amp;id=f5eda6f1da" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>\n' +
        '    <div id="mc_embed_signup_scroll" style="display: flex">\n' +
        '\t\n' +
        '<div class="mc-field-group">\n' +
        '<input type="email" value="" name="EMAIL" class="required email" style="opacity: 50%;"  placeholder="Your email address" id="mce-EMAIL">\n' +
        '</div>\n' +
        '\t<div id="mce-responses" class="clear">\n' +
        '\t\t<div class="response" id="mce-error-response" style="display:none"></div>\n' +
        '\t\t<div class="response" id="mce-success-response" style="display:none"></div>\n' +
        '\t</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->\n' +
        '    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_2e85f59032bf23a0dfb89d73e_f5eda6f1da" tabindex="-1" value=""></div>\n' +
        '    <div class="clear" style="margin-left:10px"><input type="submit" value="Sign up" name="subscribe" id="mc-embedded-subscribe" class="signUpButton" style="background-color: white!important;color:black!important"></div>\n' +
        '    </div>\n' +
        '</form>\n' +
        '</div>'

    // @ts-ignore
    return (
        <>
            <div className='background-fullscreen' style={{width:'100%',height:'150vh',position: 'fixed',top:0,left: 0}}>
            </div>
            <div data-poster-url="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-poster-00001.jpg"
                 data-video-urls="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.webm,videos/NetworkVideo-transcode.mp4"
                 data-autoplay="true" data-loop="true" data-wf-ignore="true"
                 className="w-background-video" style={{width:'100%',height:'100vh',position:'fixed',top:0,left: 0}}>
                <video autoPlay={true} loop={true} style={{width:'100%',height:'100vh',objectFit: 'cover',backgroundImage:"url('https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-poster-00001.jpeg')"}} muted={true} data-wf-ignore="true">
                    <source src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.webm" data-wf-ignore="true"/>
                    <source src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.mp4" data-wf-ignore="true"/>
                </video>
            </div>
            <div className='whole-page-section' style={{top:180,position: 'absolute'}}>
                <div className='header-text-border'>
                    <div className='header-text'>
                        SCOPEX
                    </div>
                    <div className='header-text' style={{fontWeight:'lighter',fontSize: '36px'}}>
                        AI-POWERED NFT MARKETPLACE
                    </div>
                    <div className='header-text' style={{fontWeight:'lighter',fontSize: '36px'}}>
                        COMING SOON
                    </div>
                </div>
                <div className='header-text-border' style={{marginTop:'30px'}}>
                    <div className='header-text' style={{fontSize:'24px',lineHeight: '50px'}}>
                        <img
                            style={{width:isMobile?'100%':'50%',borderRadius: '16px'}}
                            src={'https://simile.scopemedia.com/scope-active/offtheshelf/nft/tsne.jpg'}
                        />
                    </div>
                </div>
                <div className='header-text-border' style={{marginTop:'30px'}}>
                    <div className='header-text' style={{fontSize:'24px',lineHeight: '50px'}}>
                        Be the first to know
                    </div>
                    <div className='header-text' style={{fontWeight:'lighter',fontSize: '14px',lineHeight: '50px'}}>
                        Join our mailing list to be the first to know about our first release, new features and more!
                    </div>
                    <div style={{display: 'flex',justifyContent: 'center'}} dangerouslySetInnerHTML={{__html:mailForm}}></div>
                </div>

                {/*social media*/}
                <div className='header-text-border' style={{marginTop:'30px'}}>
                    <div className='header-text' style={{fontSize:'24px'}}>
                        Join The Community
                    </div>
                    <div className='homepage-footer-join-community-iconlist' style={{justifyContent:'center'}}>
                        <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://twitter.com/scopexai' }}>
                            <svg viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.57316 0.257355H61.4692C65.1921 0.257355 68.2079 3.27321 68.2079 6.99604V60.8921C68.2079 64.6149 65.1921 67.6308 61.4692 67.6308H7.57316C3.85033 67.6308 0.834473 64.6149 0.834473 60.8921V6.99604C0.834473 3.27768 3.85033 0.257355 7.57316 0.257355Z" fill="#000000"/>
                                <path d="M45.2266 28.456C45.2445 28.6976 45.2445 28.9348 45.2445 29.1764C45.3026 37.7944 38.3626 44.8329 29.7446 44.8911C29.673 44.8911 29.6014 44.8911 29.5298 44.8911C26.5229 44.9 23.5787 44.0364 21.0505 42.4122C21.4935 42.4614 21.941 42.4838 22.384 42.4793C24.8718 42.4838 27.2836 41.656 29.239 40.1212C26.8764 40.0764 24.8002 38.5372 24.0753 36.291C24.4199 36.3447 24.7689 36.376 25.1179 36.376C25.6101 36.376 26.0978 36.3089 26.5721 36.1881C23.9948 35.6645 22.1423 33.4004 22.1423 30.7694V30.7022C22.9075 31.1273 23.7621 31.369 24.6391 31.4048C22.2094 29.7805 21.4622 26.5454 22.9343 24.0173C25.7399 27.4627 29.8744 29.5612 34.3176 29.7894C34.2281 29.3733 34.1834 28.9482 34.1834 28.5231C34.1834 25.4715 36.6578 22.9971 39.7094 23.0015C41.2397 23.0015 42.6985 23.6369 43.741 24.7511C44.976 24.5139 46.1618 24.062 47.2446 23.4177C46.8329 24.6929 45.9694 25.7758 44.8194 26.4604C45.9157 26.3351 46.9851 26.0442 47.9963 25.6013C47.2401 26.702 46.3049 27.6685 45.2266 28.456Z" fill="white"/>
                            </svg>
                        </div>
                        <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://t.me/Scope_X' }}>
                            <svg  viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.73869 0H60.6348C64.3576 0 67.3735 3.01585 67.3735 6.73869V60.6347C67.3735 64.3576 64.3576 67.3734 60.6348 67.3734H6.73869C3.01585 67.3734 0 64.3576 0 60.6347V6.73869C0 3.02033 3.01585 0 6.73869 0Z" fill="#000000"/>
                                <path d="M47.6989 23.7688L43.3407 44.3518C43.014 45.806 42.1504 46.164 40.9333 45.4794L34.2842 40.5798L31.0759 43.6672C30.7582 44.0833 30.2615 44.325 29.738 44.3205L30.2168 37.5505L42.5397 26.4133C43.0767 25.9345 42.4234 25.6705 41.7075 26.1448L26.4716 35.7428L19.9119 33.6845C18.4845 33.246 18.4621 32.2571 20.2117 31.5725L45.8643 21.6882C47.0501 21.2452 48.0926 21.9566 47.6989 23.7688Z" fill="white"/>
                            </svg>
                        </div>
                        <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://discord.gg/VZhc75RR' }}>
                            <svg   viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.2858 0.257355H61.1819C64.9047 0.257355 67.9206 3.27321 67.9206 6.99604V60.8921C67.9206 64.6149 64.9047 67.6308 61.1819 67.6308H7.2858C3.56297 67.6308 0.547119 64.6149 0.547119 60.8921V6.99604C0.547119 3.27768 3.56297 0.257355 7.2858 0.257355Z" fill="#000000"/>
                                <path d="M44.908 27.1181C42.9526 25.5834 40.5677 24.6929 38.0888 24.5721L37.7532 24.9614C39.9636 25.552 42.0264 26.6036 43.7894 28.0488C38.1828 25.0151 31.5022 24.76 25.6674 27.3329C24.9783 27.6551 24.5085 27.8967 24.3206 27.9952C26.182 26.4872 28.3208 25.4357 30.6252 24.854L30.3836 24.5721C27.9047 24.6929 25.5198 25.5834 23.5644 27.1181C21.3539 31.5122 20.1548 36.3492 20.0742 41.2667C21.7701 43.6516 24.5488 45.0253 27.4707 44.9448C27.4707 44.9448 28.3611 43.853 29.086 42.9357C27.3901 42.5061 25.8911 41.4949 24.8396 40.0675C25.2021 40.3226 25.7838 40.6447 25.8375 40.6761C30.4999 43.2221 36.0663 43.5308 40.9704 41.5262C41.9548 41.1638 42.8989 40.676 43.7581 40.0988C42.6931 41.5531 41.1315 42.5777 39.3775 42.9804C40.1068 43.9112 40.9659 44.9493 40.9659 44.9493C43.9012 45.0298 46.6799 43.6427 48.3892 41.2712C48.3176 36.3447 47.1319 31.5077 44.908 27.1181ZM32.1734 36.3178C32.2405 37.7452 31.1353 38.9444 29.7079 38.9981C28.3611 38.9041 27.2962 37.8392 27.2022 36.5058C27.0948 35.0113 28.2 33.7315 29.6945 33.6107C29.7079 33.6107 29.7214 33.6241 29.7348 33.6241C31.1622 33.6913 32.254 34.8904 32.1734 36.3044C32.1734 36.3178 32.1734 36.3178 32.1734 36.3178ZM38.3438 38.5685C36.9836 38.4611 35.9589 37.2485 36.0797 35.8883C36.1871 34.6085 37.2655 33.6107 38.5586 33.6107C39.986 33.6778 41.0912 34.877 41.0241 36.3044C40.9167 37.6826 39.7041 38.6893 38.3438 38.5685Z" fill="white"/>
                            </svg>
                        </div>

                        <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://www.reddit.com/r/scopexai/' }}>
                            <svg   viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.64225 0.257355H61.5383C65.2611 0.257355 68.277 3.27321 68.277 6.99604V60.8921C68.277 64.6149 65.2611 67.6308 61.5383 67.6308H7.64225C3.91942 67.6308 0.903564 64.6149 0.903564 60.8921V6.99604C0.903564 3.27768 3.91942 0.257355 7.64225 0.257355Z" fill="#000000"/>
                                <path d="M45.0586 31.5658C44.2487 31.5658 43.4701 31.8925 42.9018 32.4697C40.5885 30.9439 37.8858 30.1116 35.1161 30.0669L36.6911 22.9747L41.7026 24.0978C41.7026 25.3283 42.7005 26.3261 43.9265 26.3261C45.1704 26.3351 46.1906 25.3283 46.1951 24.0844C46.204 22.8404 45.1973 21.8202 43.9533 21.8158C43.0942 21.8113 42.3022 22.2945 41.9219 23.0642L36.3913 21.8381C36.1094 21.78 35.8275 21.959 35.7649 22.2408L34.0377 30.0579C31.2814 30.1295 28.6011 30.9662 26.2967 32.4831C25.1423 31.2974 23.2451 31.2705 22.0593 32.4249C20.8735 33.5794 20.8467 35.4766 22.0011 36.6624C22.2383 36.904 22.5112 37.1053 22.8155 37.2575C22.7126 37.7184 22.6634 38.1882 22.6678 38.658C22.6678 43.4189 28.0284 47.276 34.6149 47.276C41.2283 47.276 46.5889 43.4189 46.5889 38.658C46.5933 38.1792 46.5352 37.696 46.4143 37.2306C47.8909 36.4968 48.495 34.7025 47.7612 33.2214C47.2511 32.2012 46.2041 31.5569 45.0586 31.5658ZM27.4019 37.5528C27.393 36.3133 28.3908 35.3066 29.6258 35.2976C30.8653 35.2887 31.872 36.2865 31.881 37.5215C31.8899 38.7609 30.8921 39.7677 29.6571 39.7766C28.4221 39.7856 27.4109 38.7878 27.4019 37.5528ZM39.5727 42.8641C37.5055 44.9313 31.6707 44.9313 29.6034 42.8641C29.3886 42.6672 29.3752 42.3316 29.5721 42.1168C29.581 42.1079 29.59 42.0945 29.6034 42.0855C29.8137 41.8886 30.1404 41.8886 30.3507 42.0855C31.9302 43.7008 37.1654 43.7322 38.8121 42.0855C39.0224 41.8886 39.349 41.8886 39.5593 42.0855C39.7786 42.2779 39.7965 42.609 39.6041 42.8283C39.5996 42.8372 39.5862 42.8507 39.5727 42.8641ZM39.528 39.7856C38.2885 39.7766 37.2952 38.7654 37.3041 37.5304C37.3131 36.291 38.3243 35.2976 39.5593 35.3066C40.7988 35.3155 41.7921 36.3268 41.7832 37.5617C41.7832 38.7922 40.7809 39.7901 39.5504 39.7901C39.5414 39.7856 39.5369 39.7856 39.528 39.7856Z" fill="white"/>
                            </svg>
                        </div>
                        <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://www.instagram.com/scopex.ai/' }}>
                            <svg   viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.73868 0.257355H60.6347C64.3576 0.257355 67.3734 3.27321 67.3734 6.99604V60.8921C67.3734 64.6149 64.3576 67.6308 60.6347 67.6308H6.73868C3.01585 67.6308 0 64.6149 0 60.8921V6.99604C0 3.27768 3.01585 0.257355 6.73868 0.257355Z" fill="#000000"/>
                                <path d="M33.6934 27.0376C29.8766 27.0376 26.7847 30.1295 26.7847 33.9463C26.7847 37.7631 29.8766 40.855 33.6934 40.855C37.5102 40.855 40.6022 37.7631 40.6022 33.9463C40.6066 30.1384 37.5281 27.0465 33.7203 27.0376C33.7113 27.0376 33.7024 27.0376 33.6934 27.0376ZM33.6934 38.4388C31.2145 38.4388 29.201 36.4297 29.201 33.9463C29.201 31.4629 31.2101 29.4538 33.6934 29.4538C36.1723 29.4538 38.1859 31.4629 38.1859 33.9463C38.1814 36.4252 36.1723 38.4298 33.6934 38.4388ZM42.4904 26.7557C42.4904 27.6506 41.7655 28.371 40.8751 28.371C39.9847 28.371 39.2598 27.6461 39.2598 26.7557C39.2598 25.8652 39.9847 25.1404 40.8751 25.1404C41.7611 25.1359 42.4859 25.8518 42.4904 26.7378C42.4904 26.7423 42.4904 26.7512 42.4904 26.7557ZM47.0724 28.3934C47.1171 26.2993 46.3341 24.2723 44.8977 22.7465C43.3719 21.3101 41.3449 20.5316 39.2508 20.5718C37.027 20.4466 30.3599 20.4466 28.136 20.5718C26.0419 20.5271 24.0105 21.3057 22.4847 22.742C21.0483 24.2678 20.2653 26.2948 20.31 28.3844C20.1847 30.6083 20.1847 37.2754 20.31 39.4992C20.2653 41.5933 21.0483 43.6203 22.4847 45.1461C24.0105 46.578 26.0375 47.361 28.1316 47.3208C30.3554 47.446 37.0225 47.446 39.2464 47.3208C41.3405 47.3655 43.3674 46.5825 44.8933 45.1461C46.3296 43.6203 47.1082 41.5933 47.0679 39.4992C47.1932 37.2754 47.1932 30.6128 47.0679 28.3889L47.0724 28.3934ZM44.1952 41.8886C43.7343 43.061 42.8081 43.9872 41.6358 44.4481C39.8638 45.1506 35.6533 44.985 33.6934 44.985C31.7336 44.985 27.5185 45.1416 25.7511 44.4481C24.5788 43.9872 23.6525 43.061 23.1916 41.8886C22.4891 40.1167 22.6547 35.9062 22.6547 33.9463C22.6547 31.9864 22.4981 27.7714 23.1916 26.004C23.6525 24.8316 24.5788 23.9054 25.7511 23.4445C27.523 22.742 31.7336 22.9076 33.6934 22.9076C35.6533 22.9076 39.8683 22.751 41.6358 23.4445C42.8081 23.9054 43.7343 24.8316 44.1952 26.004C44.8977 27.7759 44.7322 31.9864 44.7322 33.9463C44.7322 35.9062 44.8977 40.1212 44.1952 41.8886Z" fill="white"/>
                            </svg>
                        </div>
                        <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='/' }}>
                            <svg  viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.35489 0.257355H61.2509C64.9738 0.257355 67.9897 3.27321 67.9897 6.99604V60.8921C67.9897 64.6149 64.9738 67.6308 61.2509 67.6308H7.35489C3.63206 67.6308 0.616211 64.6149 0.616211 60.8921V6.99604C0.616211 3.27768 3.63654 0.257355 7.35489 0.257355Z" fill="#000000"/>
                                <path d="M26.864 47.4192H21.2753V29.427H26.864V47.4192ZM24.0629 26.9705C22.2686 26.9794 20.8055 25.5341 20.792 23.7354C20.7831 21.9411 22.2284 20.4779 24.0271 20.4645C25.8214 20.4555 27.2846 21.9008 27.298 23.6996C27.3025 25.4983 25.8572 26.957 24.0629 26.9705ZM47.7781 47.4192H42.1983V38.658C42.1983 36.5729 42.158 33.8926 39.2943 33.8926C36.3903 33.8926 35.9429 36.1612 35.9429 38.5059V47.4147H30.3631V29.427H35.7236V31.8791H35.7997C36.8915 30.0087 38.9229 28.8945 41.0842 28.9751C46.7445 28.9751 47.7781 32.6979 47.7781 37.5349V47.4192Z" fill="white"/>
                            </svg>
                        </div>

                    </div>
                </div>

                {/*about*/}
                <div className='header-text-border-features' id="about" >
                    <div className='header-text-border-features-subSections' >
                    <div className='header-text' style={{textAlign:'left'}}>
                        About
                    </div>
                    <div className='header-text-about' style={{fontWeight:'lighter'}}>
                        ScopeX is the first visual search NFT marketplace. We’re using #blockchain to create digital collectibles that you can search for with a picture instead of a hashtag or URL—giving everyone complete control over their content and ownership of their creative. Our mission is to empower creators to explore a new frontier in content creation and collaboration. Join us on Discord: and check us out here:
                    </div>
                    </div>
                </div>

                {/*features*/}
                <div className='header-text-border-features' id="features">
                    <div className='header-text-border-features-subSections' >
                        <div className='header-text' style={{textAlign:'left'}}>
                            AI-Powered  Platform
                        </div>
                        <div className='header-text-about' style={{fontWeight:'lighter'}}>
                            ScopeX, a decentralized marketplace to trade NFT’s. Powered by blockchain, AI and image recognition technology. Our goal is to empower everyday users to buy, sell, and collect high-quality artwork.
                        </div>
                    </div>
                    <div className='header-text-border-features-subSections' >
                        <div className='header-text' style={{textAlign:'left'}}>
                            Visual Search Engine
                        </div>
                        <div className='header-text-about' style={{fontWeight:'lighter'}}>
                            The first NFT visual search engine for digital assets. ScopeX’s visual search engine helps users find similar digital assets by visual similarity. This provides users with instant access to detailed information including description, category, prices, and minted details.
                        </div>
                    </div>
                    <div className='header-text-border-features-subSections' >
                        <div className='header-text' style={{textAlign:'left'}}>
                            Search Across Platforms
                        </div>
                        <div className='header-text-about' style={{fontWeight:'lighter'}}>
                            Our cross-platform NFT marketplace is a searchable decentralized NFT marketplace. This allows users to discover new NFT’s across the blockchain platform from categories, collections, artists, digital assets, and more!
                        </div>
                    </div>
                    <div className='header-text-border-features-subSections' >
                        <div className='header-text' style={{textAlign:'left'}}>
                            Personalization
                        </div>
                        <div className='header-text-about' style={{fontWeight:'lighter'}}>
                            Our personalized experience can offer an elevated experience tailored to each individual.  This leads to maximizing the experience for each person, making ScopeX NFT Marketplace easier, more shareable, and personalized for each user
                        </div>
                    </div>
                </div>

                {/*contact us*/}
                <div className='header-text-border-features' id='contact'>
                    <div className='header-text-border-features-subSections' >
                        <div className='header-text' style={{textAlign:'left'}}>
                            Contact Us
                        </div>
                        <div className='header-text-about' style={{fontWeight:'lighter',marginBottom:'30px'}}>
                            Have a question? Need more information about ScopeX? Leave us a message. We’ll get back to you soon.
                        </div>
                        <div dangerouslySetInnerHTML={{__html:contactForm}}></div>

                        {/*<form action="https://us5.list-manage.com/contact-form/post?u=2e85f59032bf23a0dfb89d73e&form_id=f3a5f1c33ba6df0091e9f4ca50ad2778" method="POST" noValidate>*/}
                        {/*    <div style={{}} aria-hidden='true' aria-label="Please leave the following three fields empty">*/}
                        {/*        <label htmlFor="b_name">Name: </label>*/}
                        {/*        <input type="text" name="fields.3094"  placeholder="" id="fields.3094" value={fNameValue} onChange={ (e)=>{setFNameValue(e.target.value) }}/>*/}

                        {/*        <label htmlFor="b_email">Email: </label>*/}
                        {/*        <input type="email" name="fields.3090" placeholder="" id="fields.3090" value={emailValue} onChange={ (e)=>{setEmailValue(e.target.value) }}/>*/}

                        {/*        <label htmlFor="b_comment">Message: </label>*/}
                        {/*        <textarea name="fields.3098" placeholder="" id="fields.3098" value={messageValue} onChange={ (e)=>{setMessageValue(e.target.value) }}/>*/}
                        {/*        <button type="button" onClick={contactUsFormSubmit} ><span>Submit</span></button>*/}
                        {/*    </div>*/}
                        {/*</form>*/}
                    </div>
                </div>

                {/*footer*/}
                <div className='header-text-border' style={{marginTop:'230px',height:'300px',backgroundColor: 'black',width: '99vw', marginLeft:isMobile?'-15px':'-48px'}}>
                    <div style={{display:isMobile?'block':'flex'}}>
                        <div style={{width: isMobile?'100%':'50%',margin: '20px'}}>
                            <div className='homepage-footer-join-community'>
                            </div>
                            <div className='homepage-footer-join-community-iconlist'>
                                <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://twitter.com/scopexai' }}>
                                    <svg viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.57316 0.257355H61.4692C65.1921 0.257355 68.2079 3.27321 68.2079 6.99604V60.8921C68.2079 64.6149 65.1921 67.6308 61.4692 67.6308H7.57316C3.85033 67.6308 0.834473 64.6149 0.834473 60.8921V6.99604C0.834473 3.27768 3.85033 0.257355 7.57316 0.257355Z" fill="#FFFFFF"/>
                                        <path d="M45.2266 28.456C45.2445 28.6976 45.2445 28.9348 45.2445 29.1764C45.3026 37.7944 38.3626 44.8329 29.7446 44.8911C29.673 44.8911 29.6014 44.8911 29.5298 44.8911C26.5229 44.9 23.5787 44.0364 21.0505 42.4122C21.4935 42.4614 21.941 42.4838 22.384 42.4793C24.8718 42.4838 27.2836 41.656 29.239 40.1212C26.8764 40.0764 24.8002 38.5372 24.0753 36.291C24.4199 36.3447 24.7689 36.376 25.1179 36.376C25.6101 36.376 26.0978 36.3089 26.5721 36.1881C23.9948 35.6645 22.1423 33.4004 22.1423 30.7694V30.7022C22.9075 31.1273 23.7621 31.369 24.6391 31.4048C22.2094 29.7805 21.4622 26.5454 22.9343 24.0173C25.7399 27.4627 29.8744 29.5612 34.3176 29.7894C34.2281 29.3733 34.1834 28.9482 34.1834 28.5231C34.1834 25.4715 36.6578 22.9971 39.7094 23.0015C41.2397 23.0015 42.6985 23.6369 43.741 24.7511C44.976 24.5139 46.1618 24.062 47.2446 23.4177C46.8329 24.6929 45.9694 25.7758 44.8194 26.4604C45.9157 26.3351 46.9851 26.0442 47.9963 25.6013C47.2401 26.702 46.3049 27.6685 45.2266 28.456Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://t.me/Scope_X' }}>
                                    <svg  viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.73869 0H60.6348C64.3576 0 67.3735 3.01585 67.3735 6.73869V60.6347C67.3735 64.3576 64.3576 67.3734 60.6348 67.3734H6.73869C3.01585 67.3734 0 64.3576 0 60.6347V6.73869C0 3.02033 3.01585 0 6.73869 0Z" fill="#FFFFFF"/>
                                        <path d="M47.6989 23.7688L43.3407 44.3518C43.014 45.806 42.1504 46.164 40.9333 45.4794L34.2842 40.5798L31.0759 43.6672C30.7582 44.0833 30.2615 44.325 29.738 44.3205L30.2168 37.5505L42.5397 26.4133C43.0767 25.9345 42.4234 25.6705 41.7075 26.1448L26.4716 35.7428L19.9119 33.6845C18.4845 33.246 18.4621 32.2571 20.2117 31.5725L45.8643 21.6882C47.0501 21.2452 48.0926 21.9566 47.6989 23.7688Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://discord.gg/VZhc75RR' }}>
                                    <svg   viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.2858 0.257355H61.1819C64.9047 0.257355 67.9206 3.27321 67.9206 6.99604V60.8921C67.9206 64.6149 64.9047 67.6308 61.1819 67.6308H7.2858C3.56297 67.6308 0.547119 64.6149 0.547119 60.8921V6.99604C0.547119 3.27768 3.56297 0.257355 7.2858 0.257355Z" fill="#FFFFFF"/>
                                        <path d="M44.908 27.1181C42.9526 25.5834 40.5677 24.6929 38.0888 24.5721L37.7532 24.9614C39.9636 25.552 42.0264 26.6036 43.7894 28.0488C38.1828 25.0151 31.5022 24.76 25.6674 27.3329C24.9783 27.6551 24.5085 27.8967 24.3206 27.9952C26.182 26.4872 28.3208 25.4357 30.6252 24.854L30.3836 24.5721C27.9047 24.6929 25.5198 25.5834 23.5644 27.1181C21.3539 31.5122 20.1548 36.3492 20.0742 41.2667C21.7701 43.6516 24.5488 45.0253 27.4707 44.9448C27.4707 44.9448 28.3611 43.853 29.086 42.9357C27.3901 42.5061 25.8911 41.4949 24.8396 40.0675C25.2021 40.3226 25.7838 40.6447 25.8375 40.6761C30.4999 43.2221 36.0663 43.5308 40.9704 41.5262C41.9548 41.1638 42.8989 40.676 43.7581 40.0988C42.6931 41.5531 41.1315 42.5777 39.3775 42.9804C40.1068 43.9112 40.9659 44.9493 40.9659 44.9493C43.9012 45.0298 46.6799 43.6427 48.3892 41.2712C48.3176 36.3447 47.1319 31.5077 44.908 27.1181ZM32.1734 36.3178C32.2405 37.7452 31.1353 38.9444 29.7079 38.9981C28.3611 38.9041 27.2962 37.8392 27.2022 36.5058C27.0948 35.0113 28.2 33.7315 29.6945 33.6107C29.7079 33.6107 29.7214 33.6241 29.7348 33.6241C31.1622 33.6913 32.254 34.8904 32.1734 36.3044C32.1734 36.3178 32.1734 36.3178 32.1734 36.3178ZM38.3438 38.5685C36.9836 38.4611 35.9589 37.2485 36.0797 35.8883C36.1871 34.6085 37.2655 33.6107 38.5586 33.6107C39.986 33.6778 41.0912 34.877 41.0241 36.3044C40.9167 37.6826 39.7041 38.6893 38.3438 38.5685Z" fill="black"/>
                                    </svg>
                                </div>

                                <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://www.reddit.com/r/scopexai/' }}>
                                    <svg   viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.64225 0.257355H61.5383C65.2611 0.257355 68.277 3.27321 68.277 6.99604V60.8921C68.277 64.6149 65.2611 67.6308 61.5383 67.6308H7.64225C3.91942 67.6308 0.903564 64.6149 0.903564 60.8921V6.99604C0.903564 3.27768 3.91942 0.257355 7.64225 0.257355Z" fill="#FFFFFF"/>
                                        <path d="M45.0586 31.5658C44.2487 31.5658 43.4701 31.8925 42.9018 32.4697C40.5885 30.9439 37.8858 30.1116 35.1161 30.0669L36.6911 22.9747L41.7026 24.0978C41.7026 25.3283 42.7005 26.3261 43.9265 26.3261C45.1704 26.3351 46.1906 25.3283 46.1951 24.0844C46.204 22.8404 45.1973 21.8202 43.9533 21.8158C43.0942 21.8113 42.3022 22.2945 41.9219 23.0642L36.3913 21.8381C36.1094 21.78 35.8275 21.959 35.7649 22.2408L34.0377 30.0579C31.2814 30.1295 28.6011 30.9662 26.2967 32.4831C25.1423 31.2974 23.2451 31.2705 22.0593 32.4249C20.8735 33.5794 20.8467 35.4766 22.0011 36.6624C22.2383 36.904 22.5112 37.1053 22.8155 37.2575C22.7126 37.7184 22.6634 38.1882 22.6678 38.658C22.6678 43.4189 28.0284 47.276 34.6149 47.276C41.2283 47.276 46.5889 43.4189 46.5889 38.658C46.5933 38.1792 46.5352 37.696 46.4143 37.2306C47.8909 36.4968 48.495 34.7025 47.7612 33.2214C47.2511 32.2012 46.2041 31.5569 45.0586 31.5658ZM27.4019 37.5528C27.393 36.3133 28.3908 35.3066 29.6258 35.2976C30.8653 35.2887 31.872 36.2865 31.881 37.5215C31.8899 38.7609 30.8921 39.7677 29.6571 39.7766C28.4221 39.7856 27.4109 38.7878 27.4019 37.5528ZM39.5727 42.8641C37.5055 44.9313 31.6707 44.9313 29.6034 42.8641C29.3886 42.6672 29.3752 42.3316 29.5721 42.1168C29.581 42.1079 29.59 42.0945 29.6034 42.0855C29.8137 41.8886 30.1404 41.8886 30.3507 42.0855C31.9302 43.7008 37.1654 43.7322 38.8121 42.0855C39.0224 41.8886 39.349 41.8886 39.5593 42.0855C39.7786 42.2779 39.7965 42.609 39.6041 42.8283C39.5996 42.8372 39.5862 42.8507 39.5727 42.8641ZM39.528 39.7856C38.2885 39.7766 37.2952 38.7654 37.3041 37.5304C37.3131 36.291 38.3243 35.2976 39.5593 35.3066C40.7988 35.3155 41.7921 36.3268 41.7832 37.5617C41.7832 38.7922 40.7809 39.7901 39.5504 39.7901C39.5414 39.7856 39.5369 39.7856 39.528 39.7856Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='https://www.instagram.com/scopex.ai/' }}>
                                    <svg   viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.73868 0.257355H60.6347C64.3576 0.257355 67.3734 3.27321 67.3734 6.99604V60.8921C67.3734 64.6149 64.3576 67.6308 60.6347 67.6308H6.73868C3.01585 67.6308 0 64.6149 0 60.8921V6.99604C0 3.27768 3.01585 0.257355 6.73868 0.257355Z" fill="#FFFFFF"/>
                                        <path d="M33.6934 27.0376C29.8766 27.0376 26.7847 30.1295 26.7847 33.9463C26.7847 37.7631 29.8766 40.855 33.6934 40.855C37.5102 40.855 40.6022 37.7631 40.6022 33.9463C40.6066 30.1384 37.5281 27.0465 33.7203 27.0376C33.7113 27.0376 33.7024 27.0376 33.6934 27.0376ZM33.6934 38.4388C31.2145 38.4388 29.201 36.4297 29.201 33.9463C29.201 31.4629 31.2101 29.4538 33.6934 29.4538C36.1723 29.4538 38.1859 31.4629 38.1859 33.9463C38.1814 36.4252 36.1723 38.4298 33.6934 38.4388ZM42.4904 26.7557C42.4904 27.6506 41.7655 28.371 40.8751 28.371C39.9847 28.371 39.2598 27.6461 39.2598 26.7557C39.2598 25.8652 39.9847 25.1404 40.8751 25.1404C41.7611 25.1359 42.4859 25.8518 42.4904 26.7378C42.4904 26.7423 42.4904 26.7512 42.4904 26.7557ZM47.0724 28.3934C47.1171 26.2993 46.3341 24.2723 44.8977 22.7465C43.3719 21.3101 41.3449 20.5316 39.2508 20.5718C37.027 20.4466 30.3599 20.4466 28.136 20.5718C26.0419 20.5271 24.0105 21.3057 22.4847 22.742C21.0483 24.2678 20.2653 26.2948 20.31 28.3844C20.1847 30.6083 20.1847 37.2754 20.31 39.4992C20.2653 41.5933 21.0483 43.6203 22.4847 45.1461C24.0105 46.578 26.0375 47.361 28.1316 47.3208C30.3554 47.446 37.0225 47.446 39.2464 47.3208C41.3405 47.3655 43.3674 46.5825 44.8933 45.1461C46.3296 43.6203 47.1082 41.5933 47.0679 39.4992C47.1932 37.2754 47.1932 30.6128 47.0679 28.3889L47.0724 28.3934ZM44.1952 41.8886C43.7343 43.061 42.8081 43.9872 41.6358 44.4481C39.8638 45.1506 35.6533 44.985 33.6934 44.985C31.7336 44.985 27.5185 45.1416 25.7511 44.4481C24.5788 43.9872 23.6525 43.061 23.1916 41.8886C22.4891 40.1167 22.6547 35.9062 22.6547 33.9463C22.6547 31.9864 22.4981 27.7714 23.1916 26.004C23.6525 24.8316 24.5788 23.9054 25.7511 23.4445C27.523 22.742 31.7336 22.9076 33.6934 22.9076C35.6533 22.9076 39.8683 22.751 41.6358 23.4445C42.8081 23.9054 43.7343 24.8316 44.1952 26.004C44.8977 27.7759 44.7322 31.9864 44.7322 33.9463C44.7322 35.9062 44.8977 40.1212 44.1952 41.8886Z" fill="black"/>
                                    </svg>
                                </div>
                                <div className='homepage-footer-join-community-icon-single' onClick={()=>{window.location.href='/' }}>
                                    <svg  viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.35489 0.257355H61.2509C64.9738 0.257355 67.9897 3.27321 67.9897 6.99604V60.8921C67.9897 64.6149 64.9738 67.6308 61.2509 67.6308H7.35489C3.63206 67.6308 0.616211 64.6149 0.616211 60.8921V6.99604C0.616211 3.27768 3.63654 0.257355 7.35489 0.257355Z" fill="#FFFFFF"/>
                                        <path d="M26.864 47.4192H21.2753V29.427H26.864V47.4192ZM24.0629 26.9705C22.2686 26.9794 20.8055 25.5341 20.792 23.7354C20.7831 21.9411 22.2284 20.4779 24.0271 20.4645C25.8214 20.4555 27.2846 21.9008 27.298 23.6996C27.3025 25.4983 25.8572 26.957 24.0629 26.9705ZM47.7781 47.4192H42.1983V38.658C42.1983 36.5729 42.158 33.8926 39.2943 33.8926C36.3903 33.8926 35.9429 36.1612 35.9429 38.5059V47.4147H30.3631V29.427H35.7236V31.8791H35.7997C36.8915 30.0087 38.9229 28.8945 41.0842 28.9751C46.7445 28.9751 47.7781 32.6979 47.7781 37.5349V47.4192Z" fill="black"/>
                                    </svg>
                                </div>

                            </div>
                        </div>
                        <div style={{width:  isMobile?'100%':'50%',margin: '20px'}}>
                            <div className='homepage-footer-join-community' style={{color:'white'}}>
                                Be the first to know
                            </div>
                            <div className='homepage-footer-join-community' style={{fontSize: isMobile?'12px':'18px',lineHeight: isMobile?'unset':'20px',color:'white',height:'50px'}}>
                                Join our mailing list to be the first to know about our first release, new features and more!
                            </div>
                            <div dangerouslySetInnerHTML={{__html:mailFormFooter}}></div>
                        </div>
                    </div>

                </div>


            </div>



       </>
    )
}

export default OfficialHomepageAllInOne
