import About from "../../components/OfficialHomepage/About";


const Feature = () => {

  return (
    <div>
      <About />
    </div>
  )
}

export default Feature
