import React from "react"

import utils from "../../utils/tools"

import './OfficialHomepage.css'
import Footer from './footer'


const About = () => {
    const isMobile:boolean = utils.isMobile()
    return (
        <>
            <div className='whole-page-section' style={{top:0}}>
                <div className='header-text-border-about' >
                    <div className='header-text' style={{textAlign:'left'}}>
                        About
                    </div>
                    <div className='header-text-about' style={{fontWeight:'lighter'}}>
                        ScopeX is the first visual search NFT marketplace. We’re using #blockchain to create digital collectibles that you can search for with a picture instead of a hashtag or URL—giving everyone complete control over their content and ownership of their creative. Our mission is to empower creators to explore a new frontier in content creation and collaboration. Join us on Discord: and check us out here:
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default About
