import { useWeb3React } from '@web3-react/core'
import { utils, BigNumber } from 'ethers'
import { Text, Box, Heading, Grid, Divider, Button, Input, Image, Label,Alert,Message,Link ,Textarea} from 'theme-ui'
import { Token } from '..'
import { updateTokensOnSale, updateUser, mintTokens, getIPFSSignature, getSignatureCoinContract } from '../../actions'
import {ActionType, useStateContext} from '../../state'
import { FormEvent, MouseEvent, useState,useCallback ,useEffect} from 'react'
import { AbstractConnector } from '@web3-react/abstract-connector'

import axios from 'axios';
import {connectorsByName} from "../../connectors";
// @ts-ignore
import ImageUploader from 'react-images-upload'

import getUserProfile from '../../actions/userProfile'
import { useHistory } from 'react-router'
import urlComponent from 'url'


const fetch = require('node-fetch')
const HDWalletProvider = require('@truffle/hdwallet-provider')
const { ethers } = require('ethers')



export type MintProps = {}

const Mint = () => {
  const { state, dispatch } = useStateContext()
  const { contract, user, activatingConnector } = state
  const { library, connector, activate } = useWeb3React()

  const [price, setPrice] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [url, setUrl] = useState('')
  const [image, setImage] = useState('')
  const [processing, setProcessing] = useState(false)
  const [message, setMessage] = useState('')
  let [signatureIPFS,setSignatureIPFS] = useState('')

  const [signatureImage, setSignatureImage] = useState([])
  let [pictures, setPictures] = useState([])
  let [displaySignatureUploader, setDisplaySignatureUploader] = useState(false)
  let [mintStep, setMintStep] = useState(1)
  let [userAccountInfo, setUserAccountInfo] = useState({id:null,username:'',artist:'',description: '', avatar: '',signature: ''})
  let [contentType,setContentType]= useState('IMAGE')

  const history : any = useHistory()
  const queryParse:any = urlComponent.parse(history.location.search,true)

  const getProfile = useCallback(async () => {
    setUserAccountInfo (await getUserProfile(true) )
  }, [])
  useEffect(() => {
    getProfile()
  }, [getProfile])


    // if (!user) return null
  // console.log(user)
  //   const { address, balance, ownedTokens } = user

  // @ts-ignore
  const onDropSignature = picture => {
    // @ts-ignore
    setSignatureImage([...signatureImage, picture]);
  }
  // @ts-ignore
  const onDropImage = picture => {
    // @ts-ignore
    setPictures([...pictures, picture]);
    let name = picture[0]?picture[0]?.name:''
    if(name.indexOf('.mp4') !== -1 || name.indexOf('.MOV') !== -1 || name.indexOf('.mov') !== -1 || name.indexOf(':video') !== -1) {
      contentType = 'VIDEO'
      setContentType(contentType)
    }
  }


  const  imageSave = async (event:any) => {
    // if(!user?.address) return
    // TODO
    // // @ts-ignore
    // const paySignatureCoinFee = await  getSignatureCoinContract(user?.address)
    // console.log('paySignatureCoinFee:'+ paySignatureCoinFee)
    // // @ts-ignore
    // if(paySignatureCoinFee !== true) return

    // let file = event.target.files[0];
    const formData = new FormData;
    // formData.append('file', file)
    formData.append('file', pictures[0][0])
    // // @ts-ignore
    // if(userAccountInfo && userAccountInfo.signature){
    //   // @ts-ignore
    //   formData.append('signature', userAccountInfo.signature)
    // }
    // if(userAccountInfo) {
    //   formData.append('creatorInfo', JSON.stringify(userAccountInfo))
    //   // @ts-ignore
    //   formData.append('creator', userAccountInfo.artist)
    // }
    const id_token = localStorage.getItem("scopexUserTokenId")

    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_SCOPEX_AI_API_URL}/watermarks/create?content_type=${contentType}`,
      headers: {Authorization: 'Bearer ' + id_token},
      data: formData
    }).then(async res => {
      setImage(res.data.imageUrl)
      setMessage(res.data.message)
      let ipfsData = await getIPFSSignature({
        signature:userAccountInfo.signature,
        creator: userAccountInfo.artist,
        image:res.data.imageUrl,
      })
      setSignatureIPFS(ipfsData)
      setMintStep(2)
    })
  }

  const onMintTokenClick = async () => {
    if(!user){
      Object.keys(connectorsByName).map((name: string) => {
        const currentConnector = connectorsByName[name as keyof typeof connectorsByName]
        const activating = currentConnector === activatingConnector
        const connected = currentConnector === connector
         dispatch({ type: ActionType.SET_CONNECTOR, payload: currentConnector })
         activate(
             connectorsByName[name as keyof typeof connectorsByName] as AbstractConnector
         )
      })
      return
    }
    // value check
    let valueError = false
    let valueErrorMsg = ''
    if(!url || url.length===0) {
      valueError = true
      valueErrorMsg = "URL can't be empty"
    }
    if(!price) {
      valueError = true
      valueErrorMsg = "you need set up a price"
    }
    if(!name || name.length===0) {
      valueError = true
      valueErrorMsg = "name can't be empty"
    }
    if(!description || description.length===0) {
      valueError = true
      valueErrorMsg = "description can't be empty"
    }
    if(!image || image.length===0) {
      valueError = true
      valueErrorMsg = "you need upload image for token"
    }
    if(valueError){
      alert("Warning: "+valueErrorMsg+".please check it and submit later.")
      return
    }


    try {
      setProcessing(true)
      let ipfsURLs = []
      ipfsURLs.push({
        name,
        image,
        description,
        priceInEth:price,
        external_url: url,
        uri: url,
        signatureIPFS:''+signatureIPFS,
        message,
        userAccountInfo
      })
      const newTokenInfo : any = {
        name,
        description,
        priceWei:utils.parseEther(price),
        ipfsURLs,
        uri:url,
        // tokenURI
      }

      await mintTokens({
        contract: contract?.payload,
        userAccount: user.address,
        library,
        dispatch,
        newTokenInfo
      }).then(res=>{
        // console.log('mint finish')
        // console.log(res)
      })
    } catch (e) {
    }
  }

  // @ts-ignore
  return (
    <Box>
      <Heading as="h1">Create New NFT Token</Heading>
      <Divider />
      <Message sx={{ display: processing?'inline':'none' , marginTop:'10px'}}
      >Token is Minting,please stay in this page, an automatic redirect will actioned when token minted!</Message>
      <Grid columns={['1fr', '1fr 1fr']} sx={{ overflow: 'hidden', gap: '0 20px',marginTop:'20px' }}>

        <Box sx={{display:mintStep ===1?'inline':'none'}}>
         <Heading as="h4" sx={{ color: 'black',fontWeight:'bold' }}>
            Upload an your image
          </Heading>
          <ImageUploader
              withIcon={true}
              buttonText='Choose file'
              onChange={onDropImage}
              imgExtension={['.jpeg','.jpg', '.gif', '.png', '.gif','.webp','mp4','mov']}
              maxFileSize={524288000}
              label ={'Max file size: 50mb'}
              singleImage={true}
              accept={"accept=*/*"	}
              withPreview={contentType === 'IMAGE'}
          />
          {
            (userAccountInfo && userAccountInfo.signature && userAccountInfo.signature !== 'https://rmacl.org/rmacl/wp-content/uploads/placeholder.png')?(
              <Box>
                <Heading as="h4" sx={{ color: 'black',fontWeight:'bold' }}>
                  Certificate
                </Heading>
              <Image sx={{width: '150px', height: '150px'}} src={userAccountInfo.signature}/>
              {/*<div style={{marginLeft: '0', cursor: 'pointer'}} onClick={() => setDisplaySignatureUploader(true)}>or use new signature</div>*/}
              </Box>
            ):(
                <>

                </>
            )
          }
          <Button
              onClick={imageSave}
              disabled={processing}
              sx={{
                display:pictures&& pictures.length>0?'iniline':'none',
                width:'80px',
                float:'right',
                marginTop: '10px',
                color: 'white',
                backgroundColor: '#3333ee',
              }}
          >Generate
          </Button>
        </Box>

        <Box sx={{display:mintStep ===2?'inline':'none'}}>
          <Heading as="h4" sx={{ color: 'black',fontWeight:'bold' }}>
          Preview The Artwork with ScopeX Protocols Authentication
          </Heading>
          <Image sx={{
            maxWidth: 256,
          }}
                 src={image}
          />
          <br/>
          <a href={image} download='artworkWithDigitalSignature' target="_Blank">Save to local</a>
        </Box>
        <Box sx={{display:mintStep ===2?'inline':'none'}}>
          <Heading as="h4" sx={{ color: 'black',fontWeight:'bold' }}>
           Price
          </Heading>
          <Box sx={{display:'flex'}}>
            <Box sx={{width:'200px',display:'flex',alignItems:'center',border: '0.5px solid #979797',boxSizing: 'border-box', borderRadius: '5px'}}>
                <Image sx={{height: '25px',ml:'30px'}} src={'https://storage.opensea.io/files/6f8e2979d428180222796ff4a33ab929.svg'}></Image>
                <Box sx={{ml:'10px',fontWeight: 600,fontSize: '18px',  lineHeight: '27px', color: '#5B5B5B'}}>ETH</Box>
            </Box>
            <Box sx={{width:'200px',display:'flex',alignItems:'center',border: '0.5px solid #979797',boxSizing: 'border-box', borderRadius: '5px'}}>
              <Input sx={{border:0}} defaultValue="" placeholder="0.00" id="price" name="price" onChange={event => setPrice(event.target.value)} />
            </Box>
          </Box>

          <Heading as="h4" sx={{ mt:'20px',color: 'black',fontWeight:'bold' }}>
             Title
          </Heading>
          <Input sx={{border:'0.5px solid #979797'}}  defaultValue=""  placeholder="" id="name" onChange={event => setName(event.target.value)}/>

          <Heading as="h4" sx={{ mt:'20px',color: 'black',fontWeight:'bold' }}>
            External Link (URL)
          </Heading>
          <Input sx={{border:'0.5px solid #979797'}}  defaultValue=""
                 placeholder="" id ="url" onChange={event => setUrl(event.target.value)}/>

          <Heading as="h4" sx={{ mt:'20px',color: 'black',fontWeight:'bold' }}>
            Description
          </Heading>
          <Textarea sx={{border:'0.5px solid #979797'}}  defaultValue="" rows={8} id="description" onChange={event => setDescription(event.target.value)} />

          <Button
              onClick={onMintTokenClick}
              disabled={processing}
              sx={{
                marginTop: '10px',
                color: 'white',
                backgroundColor: '#3333ee',
              }}
          >{user? 'Create New NFT Token':'Connect wallet'}
            </Button>
        </Box>


      </Grid>

    </Box>
  )
}

export default Mint
