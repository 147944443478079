import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'theme-ui'
import reportWebVitals from './reportWebVitals'
import { Root,RootSearch,RootHomepage } from './layout'
import { StateProvider } from './state'
import theme from './theme'

// console.log('PLATFORM'+ process.env.REACT_APP_PLATFORM)


document.title = "ScopeX.AI"
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StateProvider>
          {
              process.env.REACT_APP_PLATFORM === 'homepage' ?
                  <RootHomepage/> :
                  <></>
          }
          {
              process.env.REACT_APP_PLATFORM === 'search' ?
                  <RootSearch/> :
                  <></>
          }
          {
              process.env.REACT_APP_PLATFORM === 'market' ?
                  <Root/> :
                  <></>
          }
      </StateProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
