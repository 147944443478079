import OfficialFeature from "../../components/OfficialHomepage/OfficialFeature";


const Feature = () => {

  return (
    <div>
      <OfficialFeature />
    </div>
  )
}

export default Feature
