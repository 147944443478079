import { ActionProps } from '.'
import axios from 'axios'
import Web3 from 'web3'
import pinFileToIPFS from '../actions/ipfs'

import HTTPProvider from 'web3'

import { TokenProps } from '../components/Token'
import { ActionType } from '../state'
const { utils, ethers } = require('ethers')
const {abi} = require("../contracts/ScopeXNFT.json")


const ethNetwork = "wss://rinkeby.infura.io/ws/v3/4a19ab03301a4fb0b2ef238e5a17a765"
const web3 = new Web3(new Web3.providers.WebsocketProvider(ethNetwork))


type Props = ActionProps<{
  userAccount: string
  newTokenInfo: object
  contract: any
}>

const mintTokens = async ({ contract, userAccount, library, dispatch, newTokenInfo }: Props) => {

    try {
    // console.log(userAccount)
    // console.log(newTokenInfo)
    const info :any = newTokenInfo
    if(info){
      const {name, priceWei, ipfsURLs } = info


        // IPFS
        // @ts-ignore
       const imageIpfs = await pinFileToIPFS({userAccount, info})
       console.log(imageIpfs)
        // end of IPFS




        // const receipt = await contract.mintCollectable(userAccount, ipfsURLs[0].uri, name, priceWei, true, '', ipfsURLs[0].message)
        // const receipt = await contract.mint(userAccount, ipfsURLs[0].uri, name, priceWei, true, '', ipfsURLs[0].message)

      const receipt = await contract.mint(userAccount, 'ipfs://'+imageIpfs, name, priceWei, true, '', ipfsURLs[0].signatureIPFS)
      console.log("receipt")
      console.log(receipt)

      const myContract = new web3.eth.Contract(abi,contract.address)
      myContract.events.Transfer({
        address: contract.address
      }, function(error:any, event:any){ console.log(event); })
          .on('data', function(event:any){
            console.log(event);
            if(receipt.hash !== event.transactionHash){
              return
            }
            let trans = event.returnValues;
            let tokenId = trans.tokenId
              console.log(trans);

              console.log('!!!!tokenId = '+ tokenId)

           const metaData = {
               "description": ipfsURLs[0].description,
               "external_url": ipfsURLs[0].uri,
               "image": ipfsURLs[0].image,
               "name":  ipfsURLs[0].name,
               "attributes": [
                   {
                       "trait_type": "message",
                       "value": ipfsURLs[0].message
                   },
               ],
               signatureIPFS:ipfsURLs[0].signatureIPFS
               // userAccountInfo:ipfsURLs[0].userAccountInfo
           }

            const data = {
              tokenId,
              name,
              "network" : "ETHEREUM",
              "contractAddress" : contract.address,
              "price" : parseFloat( ipfsURLs[0].priceInEth),
              "currency" : "ETH",
              "qty" : 1,
              "externalUrl" :ipfsURLs[0].uri,
              "imageUrl" : ipfsURLs[0].image,
              "description" : ipfsURLs[0].description,
              "ownerWallet" : userAccount,
              "creatorAddress" : userAccount,
              "receipt": receipt,
              "transactionHash":receipt.hash,
              "metadata": JSON.stringify(metaData),
              "forSale": true,
              "isScope": true,
              "watermark":''+ipfsURLs[0].message
            }
              console.log(data);
              const id_token = localStorage.getItem("scopexUserTokenId")
              axios({
                  method: 'POST',
                  url: `${process.env.REACT_APP_SCOPEX_AI_API_URL}/items`,
                  headers: {Authorization: 'Bearer ' + id_token},
                  data
              }).then(res => {
                  console.log(res.data);
                  console.log("ready to redirect?")
                  window.location.href = window.location.origin
                })

          })
    }
  } catch (e) {
    console.log(e)
  }
}

export default mintTokens
