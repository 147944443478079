import axios from 'axios'
import FormData from 'form-data'
import {ActionProps} from "./index";


const pinataApiKey=  process.env.REACT_APP_PINATAAPIKEY
const pinataSecretApiKey = process.env.REACT_APP_PINATAAPISECRETKEY


type Props = ActionProps<{
    userAccount: string
    info: object
}>

const pinFileToIPFS =  async ({ userAccount,info }: Props) => {

      const getBlobFromUrl = (myImageUrl:string) => {
        return new Promise((resolve, reject) => {
            let request = new XMLHttpRequest();
            request.open('GET', myImageUrl, true);
            request.responseType = 'blob';
            request.onload = () => {
                resolve(request.response);
            };
            request.onerror = reject;
            request.send();
        })
    }

    const getDataFromBlob = (myBlob:any) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(myBlob);
        })
    }

    const convertUrlToImageData = async (myImageUrl:string) => {
        try {
            let myBlob = await getBlobFromUrl(myImageUrl);
            return myBlob;
        } catch (err) {
            console.log(err);
            return null;
        }
    }


    // @ts-ignore
    const {name, ipfsURLs, tokenURI } = info

    const imageUrl = ipfsURLs[0].image
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`

    let data = new FormData()
    const file =  await convertUrlToImageData(imageUrl)
    data.append('file', file)

    const metadata = JSON.stringify({name});
    data.append('pinataMetadata', metadata);
    // @ts-ignore
    return axios
        .post(url, data, {
            // maxBodyLength : 'Infinity',
            headers: {
                // 'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                'Content-Type': `multipart/form-data;`,
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataSecretApiKey
            }
        })
        .then(async function (response) {
            console.log(response)

            if(response.data.IpfsHash){
                return  await pinJSONToIPFS({
                   name,
                   description:ipfsURLs[0].description,
                   image: `ipfs://${response.data.IpfsHash}`,
               })
            }

            // return response.data.IpfsHash
            //handle response here
        })
        .catch(function (error) {
            console.log(error)

            //handle error here
        });
};
export default pinFileToIPFS


const pinJSONToIPFS =  async (JSONBody:object) => {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    return axios
        .post(url, JSONBody, {
            headers: {
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataSecretApiKey
            }
        })
        .then(function (response) {
            console.log(response)
            return response.data.IpfsHash
        })
        .catch(function (error) {
            //handle error here
        });
};
