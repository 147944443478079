import axios from 'axios'
import FormData from 'form-data'


const pinataApiKey=  process.env.REACT_APP_PINATAAPIKEY
const pinataSecretApiKey = process.env.REACT_APP_PINATAAPISECRETKEY


const getIPFSSignature =  async ( info:any ) => {
      const getBlobFromUrl = (myImageUrl:string) => {
        return new Promise((resolve, reject) => {
            let request = new XMLHttpRequest();
            request.open('GET', myImageUrl, true);
            request.responseType = 'blob';
            request.onload = () => {
                resolve(request.response);
            };
            request.onerror = reject;
            request.send();
        })
    }

    const getDataFromBlob = (myBlob:any) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(myBlob);
        })
    }

    const convertUrlToImageData = async (myImageUrl:string) => {
        try {
            let myBlob = await getBlobFromUrl(myImageUrl);
            return myBlob;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`
    let data = new FormData()
    const file =  await convertUrlToImageData(info.signature)
    data.append('file', file)
    const metadata = JSON.stringify({name:`${info.creator}'s signature`});
    data.append('pinataMetadata', metadata);
    // @ts-ignore
    return axios
        .post(url, data, {
            headers: {
                'Content-Type': `multipart/form-data;`,
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataSecretApiKey
            }
        })
        .then(async function (response) {
            console.log(response)

            if(response.data.IpfsHash){
                return  await pinJSONToIPFS({
                   name:info.creator,
                   image:info.image,
                   signatureImage: response.data.IpfsHash,
                   platform:'scopeX.ai',
                   time:Date.now()
               })
            }
        })
        .catch(function (error) {
            console.log(error)
        });
};
export default getIPFSSignature


const pinJSONToIPFS =  async (JSONBody:object) => {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    return axios
        .post(url, JSONBody, {
            headers: {
                pinata_api_key: pinataApiKey,
                pinata_secret_api_key: pinataSecretApiKey
            }
        })
        .then(function (response) {
            return response.data.IpfsHash
        })
        .catch(function (error) {
            //handle error here
        });
};
