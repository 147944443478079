import React from "react"

import utils from "../../utils/tools"

import './OfficialHomepage.css'
import Footer from './footer'


const ContactPage = () => {
    const isMobile:boolean = utils.isMobile()
    return (
        <>
            <div className='whole-page-section' style={{top:0}}>
                <div className='header-text-border-about' >
                    <div className='header-text' style={{textAlign:'left'}}>
                        Contact Us
                    </div>
                    <div className='header-text-about' style={{fontWeight:'lighter'}}>
                        If you have any questions or queries, a member of staff  will always be happy to help.Feel free to contact us by email and we will be sure to get back to you as soon as possible. mail to: contact@scopex.ai
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default ContactPage
