import ContactPage from "../../components/OfficialHomepage/Contact";


const Contact = () => {

  return (
    <div>
      <ContactPage />
    </div>
  )
}

export default Contact
