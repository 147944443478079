import OfficialFeatures from "../../components/OfficialHomepage/OfficialFeatures";


const Features = () => {

  return (
    <div>
      <OfficialFeatures />
    </div>
  )
}

export default Features
