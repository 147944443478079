import React  from "react"

import utils from "../../utils/tools"

import './OfficialHomepage.css'
import Footer from './footer'


const OfficialFeature = () => {

    const isMobile:boolean = utils.isMobile()

    return (
        <>
        <div className='whole-page-section'>
            <div className='header-text-border'>
                <div className='header-text'>
                    FEATRURE
                </div>
                <div className='header-text' style={{fontWeight:'lighter',fontSize: '36px'}}>
                    IS COMING SOON!
                </div>
            </div>
        </div>
            <Footer/>
       </>
    )
}

export default OfficialFeature
