import { Container, Header, Content, Sidebar,Sidenav,Icon,Nav,Dropdown,Navbar,
    Grid, Row, Col,
    FlexboxGrid,Button,ButtonToolbar,SelectPicker,InputGroup,Input,CheckPicker,Checkbox,RadioGroup,Radio,
    Drawer,Divider,Placeholder,List
} from 'rsuite'
// import { UserMenu, SearchPopup } from '..'
import { useCallback, useEffect, useState } from 'react'

import utils from "../../utils/tools"
import './HeaderOfficial.css'
import {useStateContext} from "../../state";
export type HeaderProps = {
  //
}
// https://scopemedia.com/images/Scopexlogo.svg
// src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/ScopeX_light_logo_Light Logo.svg"
// src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/ScopeX_dark_logo_Dark Logo.svg"

const HeaderRender = () => {
    const isMobile:boolean = utils.isMobile()
    const isHideBackground = window.location.pathname === '/features' || window.location.pathname === '/homepage'|| window.location.pathname === '/'
    let [asideMobile, setAsideMobile] = useState(false)

    const {state} = useStateContext()
    const {user} = state

    const closeAsideMobile= () =>{
        asideMobile= false
    }
    const toggleDrawerAsideMobile= () =>{
        asideMobile= true
    }

    return (
      <>
      <div id='fullScreen' style={{
          display:'none',
          width: '100%',
          height: '100%',
          background: 'rgba(0,0,0,0.4)',
          position: 'absolute',
          top: 0,
          left: 0,
          // zIndex: 99,
          opacity: 1
      }}> </div>
          {
              isMobile?(
                  <>
                      <div className="show-grid headerDivOfficial" style={{zIndex:999}}>
                          <FlexboxGrid align="middle" justify='space-around'>
                              <FlexboxGrid.Item colspan={18}  style={{ width: 160, cursor: 'pointer', marginTop:'10px'}}>
                                  <img
                                      onClick={() => {window.location.href= '/'}}
                                      src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/ScopeX_dark_logo_Dark Logo.svg"
                                      alt='ScopeX'
                                  />
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={4}>
                                  <Icon style={{float: 'right', fontSize: '32px'}}  icon='bars' onClick={()=>{setAsideMobile(true)}} />
                              </FlexboxGrid.Item>
                          </FlexboxGrid>
                      </div>
                  <Drawer
                      style={{width:'100%'}}
                      show={asideMobile}
                      onHide={closeAsideMobile}
                  >
                      <div className="show-grid headerDivOfficial" style={{zIndex:999}}>
                          <FlexboxGrid align="middle" justify='space-around'>
                              <FlexboxGrid.Item colspan={18}  style={{ width: 160, cursor: 'pointer', marginTop:'10px'}}>
                                  <img
                                      onClick={() => {window.location.href= '/'}}
                                      src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/ScopeX_dark_logo_Dark Logo.svg"
                                      alt='ScopeX'
                                  />
                              </FlexboxGrid.Item>
                              <FlexboxGrid.Item colspan={4}>
                                  <Icon style={{float: 'right', fontSize: '32px'}}  icon='close' onClick={()=>{setAsideMobile(false)}} />
                              </FlexboxGrid.Item>
                          </FlexboxGrid>
                      </div>
                      <div className='searchBar_outlook'>
                          <div className='searchBar_border' >
                              <div style={{width : '40px',paddingLeft:'16px'}} onClick={()=>{window.location.href='/'}}>
                                  <svg  viewBox="0 0 512 512" className="icon-visual-search-button" style={{width:'20px'}} >
                                      <path fill="currentColor" d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z"></path>
                                  </svg>
                              </div>
                              <div style={{width : '40px',paddingLeft:'10px'}}>
                                  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" width="18" height="18" >
                                      <path d="M20 20l-6.026-6.026M8.6 16.2A7.6 7.6 0 108.6 1a7.6 7.6 0 000 15.2z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                              </div>
                              <div style={{width : '70%'}}>
                                  <Input
                                      style={{ border:0,padding:0,fontSize:'20px'}}
                                      placeholder={isMobile ? "Search Scopex" : "Search items,collections,and accounts"}
                                      onClick={()=>{window.location.href='/'}}
                                      css={{
                                          ':focus': {
                                              outline:'none'
                                          }
                                      }}
                                  />
                              </div>
                          </div>
                      </div>
                      <List hover>
                              <List.Item key='wallet' className='textSearchPopupItemsText' >
                                  <div style={{display: 'flex', marginLeft: '15px',alignItems:'center'}}>
                                      <div style={{marginTop:'',marginLeft:"15px", textDecoration: 'none',fontSize: '18px'}} onClick={() => window.location.href='/#about'}>About</div>
                                  </div>
                              </List.Item>
                          <List.Item key='wallet' className='textSearchPopupItemsText' >
                              <div style={{display: 'flex', marginLeft: '15px',alignItems:'center'}}>
                                  <div style={{marginTop:'',marginLeft:"15px", textDecoration: 'none',fontSize: '18px'}} onClick={() => window.location.href='/#features'}>Features</div>
                              </div>
                          </List.Item>
                          {/*<List.Item key='wallet' className='textSearchPopupItemsText' >*/}
                          {/*    <div style={{display: 'flex', marginLeft: '15px',alignItems:'center'}}>*/}
                          {/*        <div style={{marginTop:'',marginLeft:"15px", textDecoration: 'none',fontSize: '18px'}} onClick={() => window.location.href='/feature'}>Ecosystem</div>*/}
                          {/*    </div>*/}
                          {/*</List.Item>*/}
                          {/*<List.Item key='wallet' className='textSearchPopupItemsText' >*/}
                          {/*    <div style={{display: 'flex', marginLeft: '15px',alignItems:'center'}}>*/}
                          {/*        <div style={{marginTop:'',marginLeft:"15px", textDecoration: 'none',fontSize: '18px'}} onClick={() => window.location.href='/feature'}>Team</div>*/}
                          {/*    </div>*/}
                          {/*</List.Item>*/}
                          <List.Item key='wallet' className='textSearchPopupItemsText' >
                              <div style={{display: 'flex', marginLeft: '15px',alignItems:'center'}}>
                                  <div style={{marginTop:'',marginLeft:"15px", textDecoration: 'none',fontSize: '18px'}} onClick={() => window.location.href='/#contact'}>Contact</div>
                              </div>
                          </List.Item>

                      </List>
                      {/*<div className='connect_wallet_mobile_bar' style={{ display: user?'none':'inline' }} >*/}
                      {/*    <div className='connect_wallet_mobile_button' onClick={()=>{ window.location.href='metamask://'+window.location.href }}>Connect wallet</div>*/}
                      {/*</div>*/}
                  </Drawer>
                      <div className='background-fullscreen' style={{display:isHideBackground?'none':'block'}} >

                      </div>
                      <div data-poster-url="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-poster-00001.jpg"
                           data-video-urls="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.webm,videos/NetworkVideo-transcode.mp4"
                           data-autoplay="true" data-loop="true" data-wf-ignore="true"
                           className="w-background-video">
                          <video autoPlay={true} loop={true} style={{backgroundImage:"url('https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-poster-00001.jpeg')"}} muted={true} data-wf-ignore="true">
                              <source src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.webm" data-wf-ignore="true"/>
                              <source src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.mp4" data-wf-ignore="true"/>
                          </video>
                      </div>
                      </>
              ):(
                  <>
                  <div className="show-grid headerDivOfficial" style={{zIndex:999}}>
                      <FlexboxGrid align="middle" justify='space-around' style={{  paddingTop:'10px',width: '100%',  position: 'fixed',background: 'white'}}>
                          <FlexboxGrid.Item colspan={4}  >
                              <img
                                  style={{  marginLeft:'20px'}}
                                  onClick={() => {window.location.href= '/'}}
                                  src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/ScopeX_dark_logo_Dark Logo.svg"
                                  alt='ScopeX'
                              />
                          </FlexboxGrid.Item>
                          <FlexboxGrid.Item colspan={12} style={{  display:'flex',justifyContent: 'center'}}>
                              <div className='searchBar_outlook'>
                                  <div className='searchBar_border' >
                                      <div style={{width : '40px',paddingLeft:'16px'}} onClick={()=>{window.location.href='/'}}>
                                          <svg  viewBox="0 0 512 512" className="icon-visual-search-button" style={{width:'20px'}} >
                                              <path fill="currentColor" d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z"></path>
                                          </svg>
                                      </div>
                                      <div style={{width : '40px',paddingLeft:'10px'}}>
                                          <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" width="18" height="18" >
                                              <path d="M20 20l-6.026-6.026M8.6 16.2A7.6 7.6 0 108.6 1a7.6 7.6 0 000 15.2z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                          </svg>
                                      </div>
                                      <div style={{width : '70%'}}>
                                          <Input
                                              style={{ border:0,padding:0,fontSize:'20px'}}
                                              placeholder={isMobile ? "Search Scopex" : "Search items,collections,and accounts"}
                                              onClick={()=>{window.location.href='/'}}
                                              css={{
                                                  ':focus': {
                                                      outline:'none'
                                                  }
                                              }}
                                          />
                                      </div>
                                  </div>
                              </div>
                          </FlexboxGrid.Item>
                          <FlexboxGrid.Item colspan={6} style={{  display:'flex',justifyContent: 'right',paddingRight:'20px'}}>
                              {/*<UserMenu />*/}
                              <div style={{display: 'flex'}}>
                                <div className='menu-text' onClick={()=>{window.location.href='#about'}} >About</div>
                                <div className='menu-text' onClick={()=>{window.location.href='#features'}}>Features</div>
                                {/*<div className='menu-text' onClick={()=>{window.location.href='#feature'}} >Ecosystem</div>*/}
                                {/*<div className='menu-text' onClick={()=>{window.location.href='#feature'}}>Team</div>*/}
                                <div className='menu-text' onClick={()=>{window.location.href='#contact'}}>Contact</div>
                              </div>
                          </FlexboxGrid.Item>
                      </FlexboxGrid>
                  </div>
                      <div className='background-fullscreen' style={{display:isHideBackground?'none':'block'}} >
                          <div data-poster-url="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-poster-00001.jpg"
                               data-video-urls="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.webm,videos/NetworkVideo-transcode.mp4"
                               data-autoplay="true" data-loop="true" data-wf-ignore="true"
                               className="w-background-video">
                              <video autoPlay={true} loop={true} style={{objectFit: 'cover',backgroundImage:"url('https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-poster-00001.jpeg')"}} muted={true} data-wf-ignore="true">
                                  <source src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.webm" data-wf-ignore="true"/>
                                  <source src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.mp4" data-wf-ignore="true"/>
                              </video>
                          </div>
                      </div>
                  </>
              )
          }
          </>
    )
}

export default HeaderRender
