import React from "react"

import utils from "../../utils/tools"

import './OfficialHomepage.css'


const Features = () => {

    const isMobile:boolean = utils.isMobile()


    return (
        <>
            <div className='background-fullscreen' style={{width:'100%',height:'100vh',position: 'fixed',top:0}}>
            </div>
            <div data-poster-url="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-poster-00001.jpg"
                 data-video-urls="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.webm,videos/NetworkVideo-transcode.mp4"
                 data-autoplay="true" data-loop="true" data-wf-ignore="true"
                 className="w-background-video" style={{width:'100%',height:'100vh',position:'fixed',top:0}}>
                <video autoPlay={true} loop={true} style={{width:'100%',height:'100vh',objectFit: 'cover',backgroundImage:"url('https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-poster-00001.jpeg')"}} muted={true} data-wf-ignore="true">
                    <source src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.webm" data-wf-ignore="true"/>
                    <source src="https://simile.scopemedia.com/scope-active/offtheshelf/nft/NetworkVideo-transcode.mp4" data-wf-ignore="true"/>
                </video>
            </div>
            <div className='whole-page-section' style={{top:0,position: 'absolute'}}>
                <div className='header-text-border-features' >
                    <div className='header-text-border-features-subSections' >
                        <div className='header-text' style={{textAlign:'left'}}>
                            AI-Powered  Platform
                        </div>
                        <div className='header-text-about' style={{fontWeight:'lighter'}}>
                            ScopeX, a decentralized marketplace to trade NFT’s. Powered by blockchain, AI and image recognition technology. Our goal is to empower everyday users to buy, sell, and collect high-quality artwork.
                        </div>
                    </div>
                    <div className='header-text-border-features-subSections' >
                        <div className='header-text' style={{textAlign:'left'}}>
                            Visual Search Engine
                        </div>
                        <div className='header-text-about' style={{fontWeight:'lighter'}}>
                            The first NFT visual search engine for digital assets. ScopeX’s visual search engine helps users find similar digital assets by visual similarity. This provides users with instant access to detailed information including description, category, prices, and minted details.
                        </div>
                    </div>
                    <div className='header-text-border-features-subSections' >
                        <div className='header-text' style={{textAlign:'left'}}>
                            Search Across Platforms
                        </div>
                        <div className='header-text-about' style={{fontWeight:'lighter'}}>
                            Our cross-platform NFT marketplace is a searchable decentralized NFT marketplace. This allows users to discover new NFT’s across the blockchain platform from categories, collections, artists, digital assets, and more!
                        </div>
                    </div>
                    <div className='header-text-border-features-subSections' >
                        <div className='header-text' style={{textAlign:'left'}}>
                            Personalization
                        </div>
                        <div className='header-text-about' style={{fontWeight:'lighter'}}>
                            Our personalized experience can offer an elevated experience tailored to each individual.  This leads to maximizing the experience for each person, making ScopeX NFT Marketplace easier, more shareable, and personalized for each user
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features
